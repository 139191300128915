
import UserData from "./UserData";
var hideOPW = !UserData.hideEditCollums;
var hideNPW = !UserData.hideEditCollums;
var hidePWH = UserData.hideEditCollums;
export const COLLUMNS = [
    {title:"Id",field:"_id",editable:false},
    {title:"UserName",field:"user_name",validate:rowData=>{
        if(rowData.user_name === undefined || rowData.user_name === ""){
            return "Required"
        }
        else if(rowData.user_name.length < 3){
            return "Username needs more than 3 characters"
        }
        else{
            return true
        }
    }},
    {title:"old password",field:"oldPassword",hidden:hideOPW, initialEditValue:"*******",validate:rowData=>{
        if(rowData.oldPassword === undefined || rowData.oldPassword === ""){
            return "Required"
        }
        else if(rowData.oldPassword.length < 7){
            return "password needs more than 7 characters"
        }
        else{
            return true
        }
    }},
    {title:"new password",field:"newPassword",hidden:hideNPW, type:'password',validate:rowData=>{
        if(rowData.newPassword === undefined || rowData.newPassword === ""){
            return "Required"
        }
        else if(rowData.newPassword.length < 7){
            return "password needs more than 7 characters"
        }
        else{
            return true
        }
    }},
    {title:"passwordHash",field:"password_hash",align:"left",hidden:hidePWH,editable: false,validate:rowData=>true},
    {title:"Active",field:"active", lookup:{true:"active",false:"inactive"},validate:rowData=>{
        if(rowData.active === undefined || rowData.active === ""){
            return "Required"
        }
        else{
            return true
        }
    }},
]