import React from 'react';
import InputField from './InputField';
import SubmitButton from './SubmitButton';
import UserData from './StoredData/UserData';

class LoginForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: '',
            password: '',
            userType: 'administrator',
            token: '',
            buttonDisabled : false,
        }
    }
    setInputValue(property,value){
        value = value.trim();
        if(value.length > 20){
            return;
        }
        this.setState({
            [property]:value
        })
        if([property] === "user"){
            UserData.user = value;
        }
       
    }
    resetForm( ){
        this.setState({
            user: '',
            password: '',
            userType: 'administrator',
            buttonDisabled : false,
        })
    }
    async doLogin(){
        if(!this.state.user || !this.state.password){
            return;
        }
        this.setState({
            buttonDisabled : true
        })
        try{
            let result = await fetch('/login',{
                method: 'post',
                headers: {'Accept': 'application/json',
                        'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user: this.state.user,
                    password: this.state.password,
                    userType: this.state.userType
                })
            });
            // console.log(result);
            let postResult = await result.json();
             console.log(postResult);
                
                UserData.user = postResult.username;
                UserData.token = postResult.token;
            if(postResult.success){
                UserData.isLoggedIn = postResult.success;
                UserData.user = postResult.username;
                UserData.token = postResult.token;
                

            }
            else if(result && !postResult.success){
                this.resetForm();
                alert(result.msg)
            }
        }
        catch(error ){
            //console.log(error);
            let err = "Incorrect User Name or Password";
            this.setState({
                message: err
              })
              //console.log(error.json());
              //console.log(this.state.message);
            this.resetForm();
        }
    }
  render(){
    let error = '';
    if(this.state.message){
        
            
              error = (
                <div className="alert alert-danger" role="alert">
                    <p style={{color:'red',fontSize:'15px'}}>
                  {this.state.message}
                  </p>
                </div>
              )
              
            
            
          
    }
    return (
      <div className="loginForm">
        {error}
        Please Login
        <InputField 
            type = 'text'
            placeholder = 'Username'
            value = {this.state.user ? this.state.user : ''}
            onChange = {(value) => this.setInputValue('user',value)}

        />
        <InputField 
            type = 'password'
            placeholder = 'Password'
            value = {this.state.password ? this.state.password : ''}
            onChange = {(value) => this.setInputValue('password',value)}

        />
        <SubmitButton text = {'Login'}
                        disabled = {this.state.buttonDisabled}
                        onClick = { () => this.doLogin() }
          />
      </div>
    );
  }
  
}

export default LoginForm;
