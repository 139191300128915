import {extendObservable} from 'mobx';

/**
 * Stored user data
 */
class UserData {
    constructor() {
        extendObservable(this,{
            loading :true,
            isLoggedIn: false,
            user: "",
            userType: 'administrator',
            token:"",
            hideEditCollums:true

        }) 
    }
} 
export default new UserData();