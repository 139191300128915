import React from 'react';
import './App.css';
import UserData from './StoredData/UserData';
import LoginForm from './LoginForm';
import SubmitButton from './SubmitButton';
import AppUserTable from './AppUserTable';
import {observer} from 'mobx-react'
import companyLogoHeaderImage from './images/cirtecLogoCropped.png';
//const companyLogoHeaderImage = new URL(companyLogoImage,import.meta.url);
class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        loggedInUser: '',
        userType: 'administrator',
        token: '',
    }
    UserData.user = this.state.loggedInUser;
}
  async componentDidMount() {
    if(UserData.isLoggedIn){
      this.checkLogin()
    }
    
  }
async checkLogin(){
  try{
    let result =  await fetch('/isLoggedIn',{
        method: 'get',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: UserData.user 
      })
    } );
    let isLoggedInResult = await result.json();
     console.log(isLoggedInResult);

    if(isLoggedInResult && isLoggedInResult.success){
        UserData.loading = false;
        UserData.isLoggedIn = true;
       
    }
    else{
      UserData.loading = true;
      UserData.isLoggedIn = false;
      
    }
  }
  catch(error){
    UserData.loading = false;
      UserData.isLoggedIn = false;
     
  }
}
  async  doLogout() {
    try{
      let result =  await fetch('/logout',{
          method: 'post',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      });
      let isLoggedInResult = await result.json();
      // console.log(isLoggedInResult);

      if(isLoggedInResult ){
          
          UserData.isLoggedIn = false;
          UserData.user = '';
      }
      
    }
    catch(error){
      // console.log(error);
    }

  }
  render(){
     if(UserData.isLoading){
      return (
        <div className="app">
           <div className='companyLogo'>
            <img src ={companyLogoHeaderImage} alt="CLogo" />
            </div>
          <div className='container'>
           
            loading please wait.. 
          </div>
        </div>
      );
    }
    else{
      if(UserData.isLoggedIn){
        
        // console.log(UserData)
        return (
          <div className="app">
            <div className='companyLogo'>
              <img src ={companyLogoHeaderImage} alt="CLogo" />
              </div>

            <div className='container'>
            <SubmitButton text = {'Logout'}
                        disabled = {false}
                        onClick = { () => this.doLogout() }
              />
              <AppUserTable  UserData   />
              
            </div>
           
          </div>
        );
      }
    }
    return (
      <div className="app">
        <div className='companyLogo'>
            <img src ={companyLogoHeaderImage} alt="CLogo" />
          </div>
        <div className='container'>
   
          <LoginForm />
    
        </div>
      </div>
    );
  }
  
}

export default observer(App);
