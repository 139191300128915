import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { COLLUMNS } from "./StoredData/columns";
import UserData from "./StoredData/UserData";

//import { response } from "express";

 const AppUserTable = () => {
     
    UserData.isLoggedIn = true
    const [tableData,setTableData] = useState([])
    const [error, setError] = useState(null)
    useEffect(()=>{
        getMyAppUsers()
    },[])
  
    const getMyAppUsers =() =>{
        
         fetch('/appUsers',{
            method: 'get',
            headers:{
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'authorization': UserData.token,
            }
        } ).then(response=>response.json()).then(response=>setTableData(response))
    
    }
    
    // console.log('our table data',tableData)
    let myColumns = COLLUMNS
    return (
        <div className="appusertable">
            <h1 align="center">Welcome {UserData.user}</h1>
            <div>
                <MaterialTable columns={myColumns} data={tableData}editable={{ isDeleteHidden:(row)=>row.user_name === UserData.user, 
                    onRowAdd:(newRow) => new Promise((resolve,reject)=>{
                        // setTableData([...tableData,newRow])
                        // setTimeout(()=>resolve(),500) 

                        fetch('/appUsers',{
                            method: 'post',
                            headers:{
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'authorization': UserData.token,
                            },
                            body: JSON.stringify(newRow)
                        }).then(response=>response.json()).then(response=>{getMyAppUsers()
                            resolve()
                        })
                    }),
                    onRowUpdate:(newRow,oldRow) => new Promise((resolve,reject)=>{
                        // const updatedData = [...tableData ]
                        // updatedData[oldRow.tableData.id] = newRow
                        // setTableData(updatedData)   
                        
                        var loggedID;
                        if(oldRow.user_name === UserData.user){
                            loggedID = oldRow._id;
                        }
                        UserData.hideEditCollums = false;
                        
                        fetch('/appUsers',{
                            method: 'put',
                            headers:{
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'authorization': UserData.token,
                            },
                            body: JSON.stringify(newRow)
                        }).then(response=>{if(response.ok){ return response.json()}else{resolve(); throw response}}).then( response=>{getMyAppUsers()
                            if(loggedID === newRow._id){
                                oldRow.user_name === UserData.user ? UserData.user = newRow.user_name : UserData.user = oldRow.user_name
                            }
                            
                            UserData.hideEditCollums = true;
                            newRow.newPassword = "*******";
                            newRow.oldPassword = "*******";
                            resolve()
                            
                        }).catch(error=>{console.error('old password does not match server',error);})
                    }),
                    onRowDelete:(selRow)=> new Promise((resolve,reject)=>{
                        // const updatedData = [...tableData ]
                        // updatedData.splice( selRow.tableData.id,1) 
                        // setTableData(updatedData);
                        // setTimeout(()=>resolve(),1000) 
                        fetch('/appUsers',{
                            method: 'delete',
                            headers:{
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'authorization': UserData.token,
                            },
                            body: JSON.stringify(selRow)
                        }).then(response=>response.json()).then(response=>{getMyAppUsers()
                            resolve()
                        })
                    })
                }} title="App User List" options={
                    {
                        actionsColumnIndex:-1,
                        selection: true,
                        showSelectAllCheckbox:true,
                        showTextRowsSelected:true,
                        columnsButton:true,
                        exportButton:true,
                        
                        
                        rowStyle:(data,index)=>index %2 ===0? {background:"#f5f5f5"}:null
                    }
                    
                }localization={{
                    toolbar: {
                      exportCSVName: "Export as CSV",
                      exportPDFName: "Export as PDF"
                    }
                  }}/>
            </div>
        </div>
    )
}
export default AppUserTable;

